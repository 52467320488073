@use "../../mixins/calculations" as *;



.container {
    max-width: 1700px;
    padding: 0 var(--container-horizontal-spacing);
    width: 100%;
    margin: 0 auto;

  .container {
    padding: 0;
  }

  &.container-large {
    max-width: 1700px;
  }
  &.container-medium {
    max-width: 1400px;
  }
  &.container-small {
    max-width: 1100px;
  }
  &.container-form {
    max-width: 584px;
  }
}


