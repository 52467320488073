@use '../abstract' as *;
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";

$form-field-border-radius: 8px;

body {


  .mat-form-field-infix {
    width: 160px;
  }

  .small-input-divider {
    margin: 0 4px;
    margin-bottom: 18px;
    width: 16px;
    height: 16px;
    text-align: center;
  }

  .suffix-icon-grey-light {
    .mat-form-field-suffix {
      color: $grey-light;
    }
  }
  .suffix-icon-grey {
    .mat-form-field-suffix {
      color: $grey;
    }
  }
  .suffix-icon-primary {
    .mat-form-field-suffix {
      color: $primary-color;
    }
  }

  .mat-focused .mat-form-field-required-marker {
    color: $warn-color;
  }

  .mat-form-field-subscript-wrapper {
    font-weight: 600;
    margin-top: 0.42em;
  }

  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    margin: -0.8em -0.4em;
  }

  .mat-form-field {
    min-width: 0;

    &.full-width {
      display: block;
    }

    &.no-padding {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }

    &.mat-form-field-appearance-fill {

      .mat-form-field-label {
        color: rgba(0, 0, 0, 0.5);
      }

      .mat-form-field-suffix {
        raf-icon {
          vertical-align: middle;
          margin-top: -5px;
          margin-bottom: -3px;
        }
      }

      &.mat-form-field-medium {
        font-size: 16px;
        .mat-form-field-flex {
          padding: 16px 24px 0px 24px;
        }
        .mat-form-field-infix {
          font-weight: 700;
          padding: 0.25em 0 1em 0;
        }

        .mat-form-field-subscript-wrapper {
          padding: 0 24px;
        }
      }

      .mat-form-field-flex{
        border-radius: $form-field-border-radius;
        background: #fff;
        transition: 300ms border-color ease-in-out;
        border: 1px solid $grey-lighter;
      }
      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-infix {
        font-weight: 500;
      }

      &:not(.mat-form-field-has-label)  {
        .mat-form-field-flex{
          align-items: center;
          padding: 0.75em;
          min-height: 42px;
        }
        .mat-form-field-infix {
          padding: 0;
          border-top: 0;
        }
        .mat-select-arrow-wrapper {
          transform: translate(0);
        }
      }

      &.mat-focused:not(.mat-form-field-disabled) {
        .mat-form-field-flex {
          border-color: $primary-color;
        }
      }

      &.mat-form-field-invalid:not(.mat-form-field-disabled)  {
        .mat-form-field-flex{
          border-color: $warn-color;
        }
        &.mat-focused .mat-form-field-flex {
          border-color: $warn-color;
        }
      }
    }
  }

  // iphone zoom fix - input font-size has to be 16px and more
  @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){

    .mat-input-element, .mat-select-value {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

