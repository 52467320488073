@use '../abstract' as *;
@use 'sass:color';
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";

.loading-container {
  position: relative;
  min-height: 80px;

  .loading-container-large {
    min-height: 160px;
  }
}

.loading-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: grayscale(0.8);
  animation: showLoading 160ms ease-in forwards;
  &.loading-block-light {
    animation: showLoadingLight 160ms ease-in forwards;
  }
  .loading{
    opacity: 0;
    animation: showLoadingLoader 160ms ease-in 80ms forwards;
  }
  z-index: 200;

  &.loading-inline {
    background: none;
    position: relative;
    display: inline-flex;
    padding: 16px;
    z-index: 1;
    border-radius: 10px;

    &.loading-align-center {
      display: flex;
      justify-content: center;
    }
  }
  &.loading-small {
    &.loading-inline {
      width: 40px;
      height: 12px;
      padding: 0;
      .loading { margin-top: 1px }
    }
    .loading{
      transform: scale(0.5);
    }
  }

  &.loading-position-sticky {
      align-items: flex-start;
    .loading-wrap {
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
      max-height: 60vh;

      @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){
        top: var(--header-height);
      }
    }
  }
}



.loading{
  height: 15px;
  width: 72px;
  display: flex;
  position: relative;
  transform: scale(0.8);
  flex-shrink: 0;

  .square{
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: $primary-color;
    animation: move 500ms linear 0ms infinite;
    margin-right: 18px;
    flex-shrink: 0;

    &:first-child{
      position: absolute;
      top:0;
      left:0;
      animation: grow 500ms linear 0ms infinite;
    }

    &:last-child{
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 0;
      animation: grow 500ms linear 0s infinite reverse;
    }
  }
}

@keyframes grow {
  from {transform: scale(0,0); opacity: 0;}
  to {transform: scale(1,1); opacity: 1;}
}

@keyframes move {
  from {transform: translateX(0px)}
  to {transform: translateX(30px)}
}

@keyframes showLoading {
  from {background: color.change(#fff, $alpha:  0 ); backdrop-filter: grayscale(0)}
  to {background: color.change(#fff, $alpha:  0.85 ); backdrop-filter: grayscale(0.8)}
}

@keyframes showLoadingLight {
  from {    background: color.change($grey-lightest, $alpha: 0); backdrop-filter: grayscale(0)}
  to {    background: color.change($grey-lightest, $alpha: 0.85 ); backdrop-filter: grayscale(0.8)}
}

@keyframes showLoadingLoader {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}
