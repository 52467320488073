@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";


raf-promo-block {
   .promo-block-container {

     .promo-block-icon {
       width: 40px;
       height: 40px;
       line-height: 40px;
       img {
         display: inline-block;
         vertical-align: middle;
       }
     }

     &.mobile-layout {
       .promo-block-item {
               flex-direction: column;
               .promo-block-content {
                 margin: 0;
                 margin-top: 16px;
               }
             }
     }

      @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){

          .promo-block-item {
            flex-direction: column;
            .promo-block-content {
              margin: 0;
              margin-top: 16px;
            }
          }

      }
  }
}
