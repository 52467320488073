@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";
@use '../abstract' as *;


.my-account-section {


  .two-side-layout {
    .secondary-side--menu {
      .paper-menu {
         .paper {
           background: #fff;
         }
      }
    }
  }




  @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints) {
    .two-side-layout {
      .secondary-side--menu {
        margin: calc((var(--section-vertical-padding) * -1 ) - 1px) calc(var(--container-horizontal-spacing) * -1) var(--paper-spacing);
        background: #fff;
        .paper {
          padding: 0;
        }
      }
    }

  }

}
