@use "scss/theme-config/init-material";


@use "scss/reset";
@use "scss/bootstrap/bootstrap-grid";
@use "scss/global-variables";
@use "scss/global";
@use "scss/fonts";


@use "scss/material-override";
@use "scss/components";
@use "scss/css-components";
@use "scss/page";
@use "scss/helpers";
@use "scss/swiper-override";

@import "~swiper/swiper.min.css";
// @import "~swiper/modules/thumbs/thumbs.min.css";
@import "~swiper/modules/navigation/navigation.min.css";
@import "~swiper/modules/pagination/pagination.min.css";



