@use '../abstract' as *;

body {
  .pagination-component {
    display: flex;
    flex-direction: column;
  }
  .pagination-component_load-action {
    margin-bottom: 40px;
  }
  .pagination-component_pagination {
    display: flex;
    .mat-button {
      height: 40px;
      width: 40px;
      line-height: 40px;
      padding: 0;
      min-height: 40px;
      min-width: 40px;
      margin: 0 2px;
    }
    .current {
      height: 40px;
      width: 40px;
      line-height: 40px;
      padding: 0;
      min-height: 40px;
      min-width: 40px;
      text-align: center;
      background: $primary-color;
      color: #fff;
      border-radius: 10px;
      margin: 0 2px;
    }
    .dots {
      height: 40px;
      line-height: 40px;
      margin: 0 8px;
    }
  }
}


