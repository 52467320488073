.cart-small {
  .product-list-item .product-main-section .product-title {
   max-width: 100%;
 }

  .cart-scroll-container {
    margin: -24px;
    margin-bottom: 0;
    padding: 24px;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    max-height: calc(90vh - 220px);

  }
}

.cdk-overlay-pane.mini-cart-popover {
  max-width: 640px;
}
