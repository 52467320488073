@use "../../bootstrap/mixins/breakpoints";
@use "../../bootstrap/variables";

.product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;

  & > * {
    border-bottom: 1px solid rgba(0,0,0,0.07);
    border-right: 1px solid rgba(0,0,0,0.07);
  }
  &:after {
    content: '';
    background: #fff;
    width: 2px;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  &.layout-page {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1300px){
      grid-template-columns: repeat(3, 1fr);
      margin-left: -16px;
      margin-right: -16px;
      .product-card {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    @media (max-width: 1000px){
      grid-template-columns: repeat(2, 1fr);
    }


    @media (max-width: 600px){
      grid-template-columns: repeat(1, 1fr);

      .product-card {
        .product-card_image .product-card_image_component {
          aspect-ratio: auto;
          height: 220px;
        }
      }
    }
  }

  &.layout-with-facets {
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 1360px){
      grid-template-columns: repeat(2, 1fr);
      margin-left: -16px;
      margin-right: -16px;
      .product-card {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    @media (max-width: 600px){
      grid-template-columns: repeat(1, 1fr);
      .product-card {
        .product-card_image .product-card_image_component {
          aspect-ratio: auto;
          height: 220px;
        }
      }
    }


  }


}
