@use '../abstract' as *;

.gallery {

  .product-labels {
    position: absolute;
    z-index: 2;
    padding-left: 16px;
    padding-top: 16px;
  }

  .gallery-main-image-swiper {
    aspect-ratio: 3/2;
    border: 1px solid $grey-light;
    border-radius: 10px;

    .swiper-pagination-fraction {
      left: auto;
      right: 0;
      padding-right: 16px;
      width: auto;
      font-size: 12px;
      color: $grey;
    }

    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
      pointer-events: auto;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: #fff;
      padding: 10px;
      overflow: hidden;

      &.slide-placeholder {
        background: $grey-lighter;
      }

      .gallery-main-image {
        cursor: pointer;
      }
    }

  }

  .gallery-thumbnails {
    margin-top: 16px;

    .swiper-slide {
      flex-grow: 0;
      aspect-ratio: 7/6;
      cursor: pointer;
      &.swiper-slide-thumb-active {
        .image-container-wrap.with-border {
          border-color:$primary-color;
        }
      }
    }
    .gallery-thumbnail {

    }
  }
}
