@use '../abstract' as *;

body {
  .label-value-list.mat-list-base {

    .mat-list-item + .mat-list-item {
      border-top: 1px solid $grey-light;
    }

    .mat-list-item {
      .mat-list-item-content {
        padding: 0;
      }
    }

  }
}

