
@use "../mixins/calculations" as *;
@use "../abstract" as *;

h1, h2, h3, h4 {
  color: $primary-color;
}

.mat-typography {

  raf-text {
    .mat-h1, .mat-headline, h1{
      margin-bottom: 0;

      &.gutterBottom {
        margin-bottom: toRem(20);
      }
    }
    .mat-h2, .mat-title, h2 {
      margin-bottom: 0;
      &.gutterBottom {
        margin-bottom: toRem(20);
      }
    }
    .mat-h3, .mat-subheading-2, h3  {
      margin-bottom: 0;
      &.gutterBottom {
        margin-bottom: toRem(20);
      }
    }
    .mat-h4, .mat-subheading-1, h4{
      margin-bottom: 0;
      &.gutterBottom {
        margin-bottom: toRem(16);
      }
    }
    .mat-h5, h5 {
      margin-bottom: 0;
      font-size: toRem(14);
      line-height: toRem(18);
      font-weight: 600;
      &.gutterBottom {
        margin-bottom: toRem(8);
      }
    }
    .mat-h6, h6 {
      font-size: toRem(12);
      line-height: toRem(18);
      font-weight: 600;
      margin-bottom: 0;
      &.gutterBottom {
        margin-bottom: toRem(8);
      }
    }
    p {
      margin-bottom: 0;
      &.gutterBottom {
        margin-bottom: toRem(16);
      }
    }
    .mat-body-2 {

    }
    .mat-body-1 {

    }
    .mat-body-0 {
      font-size: toRem(12);
    }
    .weight-normal {
      font-weight: normal;
    }
    .weight-medium {
      font-weight: 500;
    }
    .weight-bold {
      font-weight: bold;
    }
    .weight-heavy {
      font-weight: 900;
    }
    .text-center {
      text-align: center;
    }
    .text-right {
      text-align: right;
    }
    .text-wrap {
      word-break: break-word;
    }
    .bold {
      font-weight: bold;
    }
    .text-component-inline {
      display: inline;
    }
    .link {

      .text-component-inline {
        position: relative;
        cursor: pointer;
        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 1px;
          background: currentColor;
          width: 100%;
          opacity: 0.4;
          transition: opacity 200ms ease-in-out;
          bottom: -2px;
        }
        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    &.native-underline {
      display: inline;

      .text-component {
        display: inline;
        cursor: pointer;

        .text-component-inline {
          display: inline;
          text-decoration: underline;
        }
      }
    }
  }
}
