@use "../../mixins/calculations" as *;
@use '../../abstract' as *;

.navigation-menu {
  background: $primary-color;
  display: flex;
  min-height: 40px;
  position: relative;

  .mat-flat-button {
    border-radius: 0;
    white-space: normal;
    padding: 10px 24px;
    line-height: 1.25;

    &.active {
      background: $secondary-color-100;
      color: $primary-color;
    }
  }

  &.mobile-layout {
    &.navigation-menu--cart-step {
      .navigation-menu-item {
        flex-grow: 1;
        .mat-button-wrapper {
          flex-direction: column;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          padding: 4px 0;
          white-space: normal;
          .cart-step-text {
            margin-top: 2px;
          }
        }
      }
    }
  }
}

.navigation-menu-hover-container {
  position: relative;
  z-index: 100;
}


.navigation-drop-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.navigation-drop-menu-paper {
  background: #fff;
  padding: 48px 32px;
  border-left: 1px solid $secondary-color-100;
  border-right: 1px solid $secondary-color-100;
  border-bottom: 2px solid $grey;
  box-shadow: $shadow-large;
}




