@use '../abstract' as *;


.mat-icon {
  &.mat-icon-12 {
    width: 12px;
    height: 12px;
    line-height: 12px;
  }
  &.mat-icon-16 {
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
  &.mat-icon-24 {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  &.mat-icon-32 {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }

  &.mat-success {
    color: $success-color;
  }
}
