.row {
  & > .mat-divider-vertical {
    display: flex;
    width: 0;
    padding: 0;
  }
}


body .mat-divider-vertical {
  &.mat-divider-light {
    border-right-color: rgba(255,255,255,0.12);
  }
}
