.action-footer {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 40px;

  &.action-footer--vertical {
    flex-direction: column;
    .mat-button-base + .mat-button-base {
      margin-top: 4px;
    }
  }

  .action-footer-fill {
    .mat-button-base {
      flex-grow: 1;
    }
  }


}
