.product-attribute {
  .mat-button-extra-small {
    margin-top: -8px;
    margin-bottom: -8px;
  }
}

.product-category-line {
  display: flex;
  align-content: flex-start;
}


.product-category-label {
  min-width: 65px;
}

.product-category-value {
  display: flex;
  flex-wrap: wrap;
}

