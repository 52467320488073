@use '@angular/material' as mat;
@use '../theme-config/material-typography-config' as typography-config;
@use '../abstract' as *;
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";

$_mat_dialog_padding: 32px;

body {

  .mat-dialog, .mat-image-dialog {
    width: 100%;
    padding: 16px;

    @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
      padding: 0px;
      .mat-dialog-container {
        border-radius: 20px;
        border: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        box-shadow: 0px -3.6px 3.7px rgb(0 0 0 / 4%), 0px -7.8px 10.3px rgb(0 0 0 / 8%), 0px -26px 82px rgb(0 0 0 / 16%);
      }
    }
  }

  .mat-image-dialog {
    .mat-dialog-container {
      padding: 16px;
      position: relative;
    }
    .mat-dialog-close-button {
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 100;
    }
    .image-container {
      height: 85vh;
      max-height: 85vh;
    }
  }

  .mat-dialog-header {
    display: flex;
    position: relative;
    padding-right: 24px;

    .mat-dialog-close-button {
      position: absolute;
      right: -24px;
      top: -24px;

      .mat-icon {
        color: $grey-dark;
      }

      &:hover {
        .mat-icon {
          color: $secondary-action-color;
        }
      }
    }
  }

  .mat-dialog-container {
    border-radius: 8px;
    border: 1px solid $grey;
    padding: $_mat_dialog_padding;
    box-shadow: 0px 1.4px 3.7px rgb(0 0 0 / 4%), 0px 5.2px 10.3px rgb(0 0 0 / 6%), 0px 14.6px 24.7px rgb(0 0 0 / 7%), 0px 48px 82px rgb(0 0 0 / 11%);
  }

  .mat-dialog-title {
    @include mat.typography-level(typography-config.$main-theme-typography-config, "headline");
    color: $primary-color;
    flex-grow: 1;
  }

  .mat-dialog-content {
    margin: 0  -#{$_mat_dialog_padding};
    padding: 8px  #{$_mat_dialog_padding};
  }

}
