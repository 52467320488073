@use '../abstract' as *;


body {
  .snackBarAlert {
    background: transparent;
    box-shadow: none;
    width: 100%;
    padding: 0;
  }
}



.alertWidget {
  background: #fff;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  border-radius: 8px;
  box-shadow: $shadow-medium;


  &:not(.alertWidget--fullPage) {
    .container-max {
      padding: 0;
    }
  }



  .alertWidget__iconWrap {
    display: flex;
    align-self: stretch;
    align-items: center;
    max-height: 64px;
    flex-shrink: 0;
  }

  .alertWidget__icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    margin-top: -6px;
    margin-bottom: -5px;
    margin-right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .mat-icon {
      height: 24px;
      width: 24px;
      .fillColor {
        fill: #fff;
      }
      .lineColor {
        stroke: #fff;
      }
    }
  }

  .alertWidget__content {
    flex-grow: 1;
    padding: 16px;
    padding-left: 0;
    font-size: 14px;
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
      margin: 0 0 9px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding-left: 24px;
        position: relative;
        font-size: 14px;

        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        word-break: break-word;
        overflow: hidden;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0px;
        }

        &:first-child:last-child {
          padding-left: 0px;
          &:before {
            display: none;
          }
        }

        &:hover {
          &:before {
            width: 15px;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 2px;
          display: block;
          height: 1px;
          width: 10px;
          background: $primary-color;
          transition: all 200ms ease-in-out;
        }
      }
    }

    .alertWidget__content__label {
      display: inline-block;
      font-size: 21px;
      margin-right: 8px;
      vertical-align: middle;
      padding: 4px 0 5px 0;
    }

    .alertWidget__content__value {
      display: inline-block;
      font-size: 21px;
      color: $primary-color;
      font-weight: 800;
      vertical-align: middle;
    }
    .mat-button-offset {
      margin-bottom: -4px;
    }
  }

  .alertWidget__action {
    display: flex;
    align-items: flex-end;
    padding: 16px 16px 16px 0;
    .mat-button {
      margin-bottom: -6px;
      margin-top: -6px;
      margin-right: -4px;
    }
  }


  &.alertWidget--warning {
    background: #d9a000;
    .styled-list, ul {
      li:before {
        background: #fff;
      }
    }
    .mat-button, .linkText  {
      color: #fff;
    }
    .mat-button.mat-primary .mat-button-focus-overlay {
      background-color: #fff;
    }
  }


  &.alertWidget--success {
    background: $success-color;

    .styled-list, ul {
      li:before {
        background: #fff;
      }
    }
    .mat-button, .linkText  {
      color: #fff;
    }
    .mat-button.mat-primary .mat-button-focus-overlay {
      background-color: #fff;
    }
  }
  &.alertWidget--danger{
    background:  $red-color;
    .styled-list, ul {
      li:before {
        background: #fff;
      }
    }
    .mat-button, .linkText  {
      color: #fff;
    }
    .mat-button.mat-primary .mat-button-focus-overlay {
      background-color: #fff;
    }
  }


}

