@use '@angular/material' as mat;
@use '../abstract' as *;
@use "../mixins/calculations" as *;
@use 'sass:meta';

$_ripple-opacity: 0.1;
// Applies the background color for a ripple. If the value provided is not a Sass color,
// we assume that we've been given a CSS variable. Since we can't perform alpha-blending
// on a CSS variable, we instead add the opacity directly to the ripple element.
@mixin _ripple-background($palette, $hue, $opacity) {
  $background-color: mat.get-color-from-palette($palette, $hue, $opacity);
  background-color: $background-color;
  @if (meta.type-of($background-color) != color) {
    opacity: $opacity;
  }
}

// colors - for dark theme it should be mixin

.mat-flat-button, .mat-raised-button, .mat-button, .mat-stroked-button {
  font-weight: 500;

  &.mat-grey {
    color: $grey-dark;
  }

  &.full-width {
    width: 100%;
  }


  &.full-width-icon-button {
    text-align: left;

    .mat-button-wrapper {
      width: 100%;
    }

    raf-icon {
      &.end-icon.end-icon {
          margin-left: auto;
      }

    }
  }
}
.mat-stroked-button {
  background-color: #fff;
}

.mat-button, .mat-icon-button, .mat-stroked-button  {

  &.mat-primary {
    color: mat.get-color-from-palette($primary-mat-action-palette);
    .mat-button-focus-overlay {
      background-color: mat.get-color-from-palette($primary-mat-action-palette);
    }
  }
  &.mat-secondary {
    color: mat.get-color-from-palette($secondary-mat-action-palette);
    .mat-button-focus-overlay {
      background-color: mat.get-color-from-palette($secondary-mat-action-palette);
    }
  }
}
.mat-stroked-button:not(.mat-button-disabled) {
  border-color: $grey;
  &.mat-primary {
    border-color: mat.get-color-from-palette($primary-mat-action-palette);
  }
  &.mat-secondary {
    border-color: mat.get-color-from-palette($secondary-mat-action-palette);
  }
}

.mat-raised-button {
  &.mat-primary:not([class*=mat-elevation-z]) {
    box-shadow: 0px 4px 3px -3px rgb(10 52 0 / 50%), 0px 3px 8px 1px rgb(96 168 48 / 21%), 0px 3px 13px 3px rgb(96 168 48 / 29%), 0px 10px 20px 3px rgb(96 168 48 / 14%);
  }
  &.mat-primary:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
    box-shadow: 0px 4px 1px -3px rgb(10 52 0 / 40%), 0px 3px 2px 0px rgb(96 168 48 / 37%), 0px 3px 6px 2px rgb(96 168 48 / 29%), 0px 3px 9px 2px rgb(96 168 48 / 14%);
  }
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {

      &.mat-primary {
        background-color: mat.get-color-from-palette($primary-mat-action-palette);
        color: mat.get-color-from-palette($primary-mat-action-palette, default-contrast);
        .mat-ripple-element {
          @include _ripple-background($primary-mat-action-palette, default-contrast, $_ripple-opacity);
        }
      }
      &.mat-secondary {
        background-color: mat.get-color-from-palette($secondary-mat-action-palette);
        color: mat.get-color-from-palette($secondary-mat-action-palette, default-contrast);
        transition: 130ms ease-in-out background-color, 130ms ease-in-out color;
        .mat-ripple-element {
          @include _ripple-background($secondary-mat-action-palette, 500, $_ripple-opacity);
        }

        @media(hover: hover) and (pointer: fine) {
          &:hover {
            background-color: $secondary-color-100;
            color: mat.get-color-from-palette($secondary-mat-action-palette);
          }
        }


      }
      &.mat-white {
        background-color: #fff;
        color: $grey-dark;

      }
}



// spacing and others


body {
  .mat-button, .mat-stroked-button, .mat-flat-button, .mat-raised-button {
    line-height: 1;
    padding: 8px 24px;
    border-radius: 10px;
    min-height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;

    &.edge-left {
      margin-left: -24px;
    }
    &.edge-right {
      margin-right: -24px;
    }

    .mat-button-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      raf-icon {
        &.start-icon {
          margin-right: 8px;
          margin-left: -4px;
        }
        &.end-icon {
          margin-left: 8px;
          margin-right: -4px;
        }
      }
    }


    &.mat-button-extra-small {
      line-height: 1;
      padding: 4px 8px;
      border-radius: 10px;
      min-height: 32px;
      font-size: toRem(14);
      &.edge-left {
        margin-left: -8px;
      }
      &.edge-right {
        margin-right: -8px;
      }
      raf-icon {
        &.start-icon {
          margin-left: 0px;
        }
        &.end-icon {
          margin-right: 0px;
        }
      }
    }


    &.mat-button-small {
      line-height: 1;
      padding: 4px 16px;
      border-radius: 10px;
      min-height: 40px;
      &.edge-left {
        margin-left: -16px;
      }
      &.edge-right {
        margin-right: -16px;
      }
    }

    &.mat-button-large {
      line-height: 1;
      padding: 12px 32px;
      border-radius: 10px;
      min-height: 56px;
      &.edge-left {
        margin-left: -32px;
      }
      &.edge-right {
        margin-right: -32px;
      }
    }
  }

  .mat-icon-button {
    width: 48px;
    height: 48px;
    line-height: 48px;

    &.edge-left {
      margin-left: -20px;
    }
    &.edge-right {
      margin-right: -20px;
    }

    &.mat-button-large {
      width: 56px;
      height: 56px;
      line-height: 56px;
    }
    &.mat-button-small {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
    &.mat-button-extra-small {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }

  .nav-icon-button {
    color: $grey;
    &:hover {
      color: $grey-dark;
    }
  }
  .remove-button, .close-button {
    color: $grey;
    &:hover {
      color: $grey-dark;
    }
  }


  .mat-button-base.hasLoading {
    position: relative;
    overflow: hidden;

    .loading-block {
      pointer-events: none;
      z-index: 1;
      position: absolute;
      background: rgb(223 223 223 / 80%);
      backdrop-filter: grayscale(1);
   }
  }
}
