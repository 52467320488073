.icon-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    padding: 4px 0;
    raf-icon {
      flex-shrink: 0;
      margin-right: 16px;
    }
  }
}
