@use '../../abstract' as *;
@use '../../mixins' as *;



.tab-container {
  border-bottom: 1px solid $grey-light;
  position: relative;
  background: #fff;
  &.tabletBreakpoint {
    background: $grey-lighter;

  }
  &.tab-container-sticky {
    position: sticky;
    top: var(--header-height);
    z-index: 10;
  }
}

.tab-nav-wrap {
  display: flex;

  .tab-nav {
    flex-wrap: nowrap;
    display: flex;
  }


  .mat-button-base {
    background: $grey-lighter;
    border-radius: 0;
    height: var(--tab-height);
    padding: 8px 18px;
    font-weight: bold;
    color: $grey-dark;
    font-size: toRem(14);
    white-space: normal;

    & + .mat-button-base {
      border-left: 1px solid $grey-light;
    }
  }
}
