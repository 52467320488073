@use '../../abstract' as *;

.product-list {
  list-style: none;
  margin: 0;
  padding: 0;
  & > :not(style) + :not(style) {
    margin-top: 16px;
  }

  &.product-list-paper {
    .product-list-item {
      background: #fff;
      padding: 24px;
      border-radius: 8px;
    }
  }

  &.product-list-dividers {
    .product-list-item + .product-list-item {
      border-top: 1px solid $grey-light;
    }
  }
}
