@use "bootstrap/mixins/breakpoints";
@use "bootstrap/variables";

:root {
  --container-horizontal-spacing: 30px;
  --layout-content-spacing-top: 50px;
  --layout-content-spacing-bottom: 50px;
  --paper-spacing: 24px;
  --section-vertical-padding: 40px;
  --paper-spacing-large: 32px;
  --tab-height: 60px;
  --tab-anchor-offset: calc((var(--tab-height) + var(--header-height)  + 24px) * -1);
  --header-height: 0px;

  @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){
    --header-height: 120px;
  }

  @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
    --container-horizontal-spacing: 16px;
    --paper-spacing-large: 24px;
    --paper-spacing: 16px;
  }
}

