@use "../../mixins/calculations" as *;
@use "../../bootstrap/mixins/breakpoints";
@use "../../bootstrap/variables";

.footer {
  background: #828282;
  padding-top: 100px;
  padding-bottom: 70px;
  color: #fff;

  .categories-container {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .category-list {
    margin-top: 8px;
    list-style: none;
    width: min-content;
    padding: 8px 0px;
    display: inline-grid;
    grid-template-columns: 1fr;
    gap: 8px 40px;



    &.category-list-col-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.category-list-col-2 {
      grid-template-columns: 1fr 1fr;
    }
    &.category-list-col-1 {
      grid-template-columns: 1fr;
    }
    li {
       width: min-content;
       font-size: toRem(16);
       a {
         white-space: nowrap;
         color: #fff;
         text-decoration: none;
         &:hover {
           text-decoration: underline;
         }
       }
    }
  }

  &.mobile-layout {
    padding: 24px 16px;

    li a {
      font-size: 14px;
    }

    ul {
      text-align: left;
    }

    .footer-address,
    .footer-social-links,
    .footer-logo {
      text-align: center;
    }

    img, .footer-socials {
      padding-top: 32px;
      padding-bottom: 16px;
    }


    .category-list {
      gap: 8px 24px;
    }
  }




  .footer-bottom-info {
    margin-top: 64px;

  }

.footer-lowest-images {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  img {
    object-fit: contain;
    max-width: 440px;
  }
  }

  .footer-address {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .footer-social-links {
    .mat-fab + .mat-fab {
        margin-left: 8px;
    }
  }



  @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){
    .category-list {
      &.category-list-col-3 {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
    .category-list {
       width: 100%;
    }
  }
}

