@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";

.catalog-page {
  .two-side-layout .primary-side {
     min-height: 60vh;
  }

  .category-header-text-image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 30px;
    gap: 32px;

    .category-header-image {
      display: flex;
      height: 220px;
      max-width: 350px;
      flex-direction: row;
      width: 100%;
      flex-shrink: 0;

      .image-container {
        display: block;
        height: 220px;
        max-width: 350px;
        text-align: center;
        .image-placeholder {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin-left: -15px;
        }
      }

      img {
        object-fit: unset;
        flex-direction: row;
        border-radius: 8px;
        display: inline-block;
        max-height: 100%;
        width: auto;
        margin-top: 110px;
        transform: translateY(-50%);
      }
    }
  }

  @media (max-width: 768px) {
    .category-header-text-image {
      display: flex;
      flex-direction: column;

      .category-header-image {
        flex-direction: column;
        margin: 0 auto;
      }
    }
  }
}
