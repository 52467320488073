@use '../abstract' as *;

body {
  .card-button {
    height: auto;
    min-height: 120px;
    border-radius: 8px;
    .mat-button-wrapper {
      flex-direction: column;
      .mat-icon {
        margin-bottom: 8px;
        margin-top: -8px;
      }
    }
  }
}
.card {
  & > .paper {
    box-shadow: $shadow-small;
    position: relative;
    transition: background-color 160ms ease-in-out, box-shadow 160ms ease-in-out;
  }

  &.selectable {
    cursor: pointer;
    & > .paper:hover {
      background: $secondary-color-50;
    }
  }

  &.active {
    & > .paper, & > .paper:hover {
       background: $secondary-color-100;
       box-shadow: 0px 2px 3px rgb(0 77 90 / 3%), 0px 3.5px 4px rgb(0 109 129 / 13%), 0px 5px 12.1px rgb(0 79 116 / 12%), 0px 10px 29px rgb(1 94 165 / 17%)
    }
  }

  .card-corner-action {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}


