@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";


.checkout-action-footer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .checkout-left {
    display: flex;

  }


  .checkout-right {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .checkout-right-more-buttons {
      display: flex;
      align-items: center;

      .mat-button-base + .mat-button-base  {
        margin-left: 8px;
      }
    }
   }
  .checkout-primary-action {

    min-width: 220px;
    .mat-button-wrapper {
      width: 100%;
      raf-icon:last-child {
        margin-left: auto;
        margin-right: -8px;
      }
    }

  }
}



@include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
  .checkout-action-footer {
    flex-direction: column;
    align-items: stretch;

    .checkout-right {
      order: 0;
      margin-left: 0;
      .mat-button-base {
        width: 100%;
        margin: 0;
      }
      .checkout-right-more-buttons {
        flex-direction: column;

        .mat-button-base {
          order: 2;
          margin: 0;
        }
        .checkout-primary-action {
          order: 1;
          margin-bottom: 8px;
        }


      }
    }

    .checkout-left {
      margin-top: 24px;
      order: 1;
      .mat-button-base {
        width: 100%;
        margin: 0;
      }
    }
  }
}

