@use '../abstract' as *;

.product-card {
  padding: 24px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .product-labels {
    position: absolute;
    z-index: 2;
    padding-left: 6px;
    padding-top: 6px;
  }

  &.no-padding.no-padding {
    padding: 0;
  }
  &.product-card-center {
    .product-card_title {
      text-align: center;
    }
    .product-card_action, .product-card_price {
      justify-content: center;
    }
  }

  &.product-card-small {
    padding: 16px;

    .product-card_image .product-card_image_component {
      aspect-ratio: 3/2;
      // max-width: 130px;
      margin:  0 auto;
    }

    .product-buy-button.mat-button-extra-small {
      height: 34px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}




.product-card_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .product-buy-button {
    margin-left: 8px;
    .mat-button-wrapper {
      white-space: normal;
    }
  }
}

.product-card_content {
  padding-top: 16px;
  padding-bottom: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .product-card_title {
    min-height: 40px;
    display: block;
    margin-bottom: 8px;
    flex-grow: 1;
  }
  .product-card_price {
    min-height: 48px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.product-card_image{

  .product-card_image_component {
    aspect-ratio: 3/2;
  }
}
