html, body { height: 100%; margin: 0;
  &.smooth-scroll {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
raf-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.mat-app-background {
  background: #fff;
}

youtube-player iframe {
  position: relative;
  display: block;
  width: 100%;
}

a {
  color: currentColor;
  cursor: pointer;
  text-decoration: none;
}

.link {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }

  @media (hover: hover) {
    &:hover {
      .text-component {
        text-decoration: underline;
      }
    }
  }
}



