@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";


.radio-card {
  background: #fff;
  border-radius: 10px;
  padding: 24px;

  .radio-card-radio {
    display: flex;
    .mat-radio-label {
      display: flex;
      .mat-radio-label-content {
        display: flex;
        flex-grow: 1;
        padding-left: 16px;
      }
    }
  }
  .mat-radio-label-content {
    padding-left: 16px;
  }
  .radio-card-radio-main-col {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    & + .radio-card-price {
      margin-left: 16px;
      min-width: 108px;
    }
    .radio-card-title-wrap {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: space-between;
      raf-image {
        width: 10rem;
        text-align: center;
      }
    }
  }

  @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
    .radio-card-radio-main-col {
      align-items: flex-start;
      flex-direction: column;
      raf-image {
        display: none;
      }
    }
  }
}
.radio-card-title {
  font-weight: bold;
  margin-right: 40px;
}
.radio-card-price {
  margin-left: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: end;
}
