@use '../abstract' as *;

.mat-typography {
  .main-price {
    small {
      font-size: 70%;
    }
  }
  .old-price {
    span {
      background-image: linear-gradient(173deg, transparent 0%, transparent 47%, #888 50%, transparent 53%, transparent 100%);
      background-position: center 0.4rem;
      background-repeat: no-repeat;
      display: inline-block;
      font-weight: 500;
    }
  }
}

.price-info {
  .price-info-icon {
    color: #777676;
  }

  .price-info-value {
    color: #777676;
    vertical-align: text-bottom;
    margin-left: 5px;
    font-size: 0.8rem;
  }
}
