@use '../../abstract' as *;

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.layout-light {
  background: $grey-lighter;
}

.layout-lighter {
  background: $grey-lightest;
}

.layout-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: calc(100vh - 200px);
  position: relative;
}
.layout-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: var(--layout-content-spacing-top);
  padding-bottom: var(--layout-content-spacing-bottom);
}
