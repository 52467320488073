@use "../../mixins/calculations" as *;
@use '../../abstract' as *;
@use 'sass:color';

.header {
  background: #fff;
  display: flex;
  min-height: 100px;

  .mobile-menu {
    background: $secondary-color-50;
    border-radius: 8px;
    line-height: 20px;
    margin-right: 8px;
    width: 42px;
    height: 42px;
    .mat-button-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 11px;
      .mobile-menu-text {
        margin-bottom: -2px;
      }
     }
  }
}

.header-container {
  display: flex;
  flex-direction: column;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-top-left {
  display: flex;
  align-items: center;
  width: 45%;
  .header-promo-text {
    margin: 0px;
    img {
      object-fit: cover;
      width: 540px;
      padding: 0px 15px;
    }
  }
  .logo {
    margin: 0px;
    img {
      object-fit: contain;
      width: 260px;
    }
  }
}

@media screen and (max-width: 1100px) {
.header-top-left {
   width: 35%;
  img {
    padding-left: 10px;
  }
}
}


@media screen and (max-width: 850px) {
  .header-top-left {
    width: 58%;

  }
}


.header-top-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  .header-search {
    max-width: 480px;
    width: 100%;
    margin-right: 40px;

    .mat-form-field.mat-form-field-appearance-fill:not(.mat-form-field-has-label) .mat-form-field-flex {
      padding: 16px 20px;
    }
   .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
      margin-right: -0.8rem;
    }
  }
}


.header-actions {
  margin-top: -2px;

  .icon-button-helper {
    position: relative;
    width: 48px;
    height: 48px;
    line-height: 48px;
  }

  .mat-icon-button {

  }
}


.mat-icon-button.logged-button {
  background: $primary-color;
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 900;
}


.header-component {
  &.tabletBreakpoint {
    position: sticky;
    top: 0;
    z-index: 210;
    transition: box-shadow 300ms ease-in-out;



    .header {
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.35);
        min-height: var(--header-height);
        position: sticky;
        top: 0;
        background: #fff;
        align-items: flex-start;
        padding-top: 12px;
        padding-bottom: 12px;

        .logo {
          max-height: 50px;
          display: flex;
          align-items: center;
        }

        .header-bottom {
          margin-top: 8px;
          position: relative;
        }

        .header-search {

        }
        .header-actions {

          .icon-button-helper {
            position: relative;
            width: 40px;
            height: 40px;
            line-height: 40px;
          }

          .mat-icon-button {
            width: 40px;
            height: 40px;
            line-height: 40px;
            .mat-icon {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

  }
}




