@use '@angular/material' as mat;
@use "../theme-config/material-config" as materialConfig;

$primary-color: mat.get-color-from-palette(materialConfig.$primary-mat-palette);
$secondary-color: mat.get-color-from-palette(materialConfig.$secondary-mat-palette);
$secondary-color-50: mat.get-color-from-palette(materialConfig.$secondary-mat-palette, 50);
$secondary-color-100: mat.get-color-from-palette(materialConfig.$secondary-mat-palette, 100);
$accent-color: mat.get-color-from-palette(materialConfig.$accent-mat-palette);
$warn-color: mat.get-color-from-palette(materialConfig.$warn-mat-palette);
$success-color: #60A830;

$primary-action-color: mat.get-color-from-palette(materialConfig.$primary-mat-action-palette);
$secondary-action-color: mat.get-color-from-palette(materialConfig.$secondary-mat-action-palette);


$red-color: #C81919;
$orange-color: #c89619;


$grey-lightest: #f8f8f8;
$grey-lighter: #F0F0F0;
$grey-light: #E6E6E6;
$grey: #C9C9C9;
$grey-dark: #707070;


$backdrop-color: $grey-light;

