@use "../abstract" as *;

.primary-color {
  color: $primary-color;
}

.accent-color {
  color: $accent-color;
}

.warn-color {
  color: $warn-color;
}

.success-color {
  color: $success-color;
}

.primary-action-color {
  color: $primary-action-color;
}

.secondary-action-color {
  color: $secondary-action-color;
}

.black-text {
  color: #000;
}

.primary-text {
  color: #3B3B3B;
}

.grey-text {
  color: $grey-dark;
}

.red-text {
  color: $red-color;
}

.light-text {
  color: $grey;
}

.inherit {
  color: inherit;
}


