@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";
@use '../abstract' as *;

.two-side-layout {
  display: flex;
  padding: 0;
  flex-grow: 1;
  .secondary-side {
    flex-shrink: 0;
  }

  &.two-side-layout--equal {
    .secondary-side {
      width: auto;
      flex-basis: 0;
      flex-grow: 1;
      padding-right: 48px;

      & + .primary-side {
        width: auto;
        flex-basis: 0;
        padding-top: 0;
        padding-left: 48px;
      }
    }
  }

  &.two-side-layout--divider {
    .secondary-side + .primary-side{
          border-left: 1px solid $grey-light;
    }
  }

  &.break-it {
    flex-direction: column;

    &.two-side-layout--divider {
      .primary-side { border-left: 0}
    }


    .secondary-side {
      width: auto;
      padding-right: 0;
      &.secondary-side--small {
        width: auto;
      }
      & + .primary-side {
        padding-left: 0px;
      }
    }


    .primary-side {
      & + .secondary-side {
        width: auto;
        padding-left: 0;
      }
    }
  }

  @include breakpoints.media-breakpoint-down(xl, variables.$grid-breakpoints){
    &.break-xl {
      flex-direction: column;

      &.two-side-layout--divider {
        .primary-side { border-left: 0}
      }


      .secondary-side {
        width: auto;
        padding-right: 0;
        &.secondary-side--small {
          width: auto;
        }
        & + .primary-side {
          padding-left: 0px;
        }
      }


      .primary-side {
        & + .secondary-side {
          width: auto;
          padding-left: 0;
        }
      }
    }
  }


  @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){
    &.break-lg {
      flex-direction: column;

      &.two-side-layout--divider {
        .primary-side { border-left: 0}
      }


      .secondary-side {
        width: auto;
        padding-right: 0;
        padding-bottom: 32px;
        &.secondary-side--small {
          width: auto;
        }
        & + .primary-side {
          padding-left: 0px;
        }
      }
      .primary-side {
        & + .secondary-side {
          width: auto;
          padding-left: 0;
        }
      }
    }
  }

  @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
    &.break-md {
      flex-direction: column;
      &.two-side-layout--divider {
        .primary-side { border-left: 0}
      }

      .secondary-side {
        width: auto;
        &.secondary-side--small {
          width: auto;
        }
        & + .primary-side {
          padding-left: 0px;
        }
      }
      .primary-side {
        & + .secondary-side {
          width: auto;
          padding-left: 0;
        }
      }
    }
  }


  .primary-side {
    flex-grow: 1;
    position: relative;
    padding-top: 8px;
  }

  .secondary-side {
    width: 300px;
    display: flex;
    flex-direction: column;
    position: relative;

    &.secondary-side--small {
      width: 230px;
    }

    & + .primary-side {
      padding-left: 48px;
    }
  }

  .primary-side {
    & + .secondary-side {
      width: 330px;
      padding-left: 16px;
    }
  }
}
