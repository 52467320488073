@use '../../abstract' as *;

.product-list-item {
  display: flex;
  position: relative;
  flex-direction: column;


  .product-list-item-in {
    display: flex;
    align-items: center;
  }

  .product-messages {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &.product-list-table-space {
    .product-main-section{
      flex-grow: 1;
      .product-title {
        max-width: 100%;
      }
    }
    .product-last-section {
      flex-grow: 0.6;
    }
  }

  .product-img {
    border-radius: 4px;
    width: 80px;
    height: 80px;
    margin-right: 24px;
    flex-shrink: 0;
      }

  .product-info-wrap {
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: 1;
  }
 .product-main-section{
   display: flex;
   align-items: center;
   flex-grow: 1;
   flex-basis: 0;

   .product-title {
     max-width: 220px;
     margin-right: 16px;
   }
 }
  .product-middle-section {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
  }
  .product-last-section {
    display: flex;
    align-items: center;
    flex-basis: 0;

    .product-price {
      width: 160px;
      margin-left: auto;
    }
    .product-quantity {
      width: 116px;
      font-weight: bold;
      &.read-only.read-only {
        width: auto;
        & > div {
          width: 60px;
          text-align: right;
        }
      }
    }
  }
  .product-actions {
    display: flex;
    align-items: center;
    .remove-button {
      margin-right: -12px;
    }
  }

  &.product-list-item-small {
    .product-img {
      width: 60px;
      height: 40px;
      margin-right: 16px;
      align-self: start;
    }

    .product-last-section {
      .product-quantity {
        width: 130px;
      }
      .product-price {
        width: 160px;
      }
    }
  }


  &.mobile-layout {
    align-items: flex-start;
    .product-info-wrap {
      flex-direction: column;
      align-items: flex-start;
    }
    .product-actions {
      position: absolute;
      right: -12px;
      top: -12px;
    }

    .product-last-section {
      justify-content: space-between;
      flex-grow: 1;
      width: 100%;

        .product-quantity.read-only.read-only > div {
         text-align: left;
      }
    }
    .product-main-section {
      margin-bottom: 16px;
    }
    .product-middle-section {
      margin-bottom: 16px;
    }
    .product-title {
      max-width: 100%;
      padding-right: 40px;
    }
    .product-price {
      width: auto;
    }

    .product-list-item-in {
      width: 100%;
      padding: 8px 0;
    }
  }
}
