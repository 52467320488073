@use '../abstract' as *;
@use '../mixins' as *;
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";


.subcategories-block-wrap {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
}

.subcategories-block {
  display: flex;
}
.subcategories-block-image {
  margin-right: 24px;
  background: $secondary-color-50;
  border-radius: 10px;
  height: 130px;
  width: 120px;
  flex-shrink: 0;
  cursor: pointer;

  .image-container-wrap {
    width: 85%;
  }
}
.subcategories-block-links {
  padding: 16px 0;
}
.subcategories-container {
  overflow: hidden;
}

.subcategories-rows {
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
  flex-wrap: wrap;
}

.subcategories-item {
  position: relative;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 4px;
  &:before {
    content: '';
    display: inline-flex;
    height: 18px;
    width: 1px;
    background: $secondary-color;
    position: absolute;
    left: 0px;
    opacity: 0.44;
  }
}


@include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){
  .subcategories-block-wrap {
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    .subcategories-block {
      flex-direction: column;
    }
    .subcategories-block-image {
      width: auto;
      height: auto;
      aspect-ratio: 4/3;
      margin: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .image-container-wrap {
        width: 85%;
      }
    }
    .subcategories-block-links {
      padding: 0;
      flex-grow: 1;
      display: flex;
       a {
         display: flex;
         background: $secondary-color-100;
         border-bottom-left-radius: 10px;
         border-bottom-right-radius: 10px;
         padding: 12px 8px;
         flex-grow: 1;
         justify-content: center;
         align-items: center;
         .text-component {
           font-size: toRem(14);
         }
       }

      .gutterBottom {
        margin-bottom: 0;
      }

      .subcategories-container {
        display: none;
      }
    }
  }
}
