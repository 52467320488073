
body {
  .datepicker-field {
    .mat-form-field-infix {
      width: 110px;
    }
  }

  .mat-calendar-controls {
    .mat-button {
      padding-left: 16px;
      padding-right: 16px;
      min-height: 42px;
    }
    .mat-icon-button {
      height: 42px;
      width: 42px;
      line-height: 42px;
    }
  }
  .mat-datepicker-toggle {
    display: flex;
  }
}
