@use '../abstract' as *;
@use 'sass:color';

raf-article-file-download {
  .download-items {
    display: flex;
    flex-direction: row;

    .download-item {
      background: #fff;
      border: solid 1px $grey-light;
      border-radius: 8px;
      height: 200px;
      width: 200px;
      padding: 12px;
      margin-right: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover {
        background: color.change($secondary-color-50, $alpha: 0.5);
      }

      raf-icon {
        color: grey;
        position: absolute;
        top: 12px;
        left: 12px;
      }

      raf-image {
        max-width: 124px;
      }

      raf-text {
        text-align: center;
      }
    }
  }
}
