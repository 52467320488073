@use '../abstract' as *;

.partners-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  .partner-item {
    max-width: 140px;
  }
}

