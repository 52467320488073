.status-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;

  .status-block_motive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
    &.success-with-document {
      margin-right: -16px;
    }
    &.error {
      width: 170px;
      height: 130px;
    }
  }
  .status-block_title {
    margin-bottom: 4px;
    text-align: center;
  }

  .status-block_subtitle {
    .text-component {
      font-weight: 600;
    }
    margin-bottom: 40px;
    text-align: center;
  }
}
