@use '../abstract' as *;
@use 'sass:color';

@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";


.search-container-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 199;
}
.search-container {
  position: relative;
  z-index: 200;

  .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
    background: color.change($secondary-color-50, $alpha: 0.2);
    border-color: color.change($primary-color, $alpha: 0.2);
  }

  &.search-open {
    .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
      border-color:  $primary-color;
    }
    .search-dropdown {
      box-shadow: $shadow-large;
      border-color: $primary-color;
    }

  }

  .search-dropdown {
    position: absolute;
    top: 100%;
    margin-top: -6px;

    background: #fff;
    left: 0;
    right: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: 300ms box-shadow ease-in-out 200ms, 300ms border-color ease-in-out 0ms;
    border: 1px solid color.change($primary-color, $alpha: 0.2);
    border-top: 0;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 30px;
      background: linear-gradient(180deg, #fff 20%, transparent 100%);
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 30px;
      background: linear-gradient(0, #fff 20%, transparent 100%);
      z-index: 2;
    }
  }
  .search-dropdown-in {
    padding: 20px;
    padding-bottom: 30px;
    overflow-y: auto;
    max-height: calc(100vh - 140px);
    overscroll-behavior-y: contain;
  }

  @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){

    .search-dropdown {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 30px;
        background: linear-gradient(180deg, #fff 20%, transparent 100%);
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30px;
        background: linear-gradient(0, #fff 20%, transparent 100%);
        z-index: 2;
      }
    }

    .search-dropdown-in {
      max-height: calc(100vh - 120px);
    }

  }

  .search-results {
    .product-list-item.product-list-item-small .product-img {
      width: 70px;
      height: 70px;
    }
  }


}
