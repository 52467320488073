@use "../../mixins/calculations" as *;
@use '../../abstract' as *;


.menu-strip {
  background: $secondary-color-100;
  display: flex;
  min-height: 40px;
  .login-button {

  }
}

.menu-strip-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
