@use '../abstract' as *;
@use 'sass:color';

.order {
  .order-action {
    display: flex;
    align-items: center;
    padding-top: 4px;
  }

  .order-content {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  .order-col {
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 0;
  }

  .order-col-id {
    flex-basis: 210px;
  }

  .order-col-products {
    flex-grow: 1;
  }

  .order-col-file {
    flex-basis: 160px;
  }

  .order-col-price {
    flex-basis: 160px;
  }

  .order-col-action {
    padding-left: 8px;
    flex-grow: 0;

    .nav-icon-button {
      margin-right: -16px;
    }
  }
}


// mobile layout
.order {
  .order-content {
    &.mobile-layout {
      flex-direction: column;
      align-items: stretch;

      .order-content-main-info-container {
        display: flex;
        flex-grow: 1;

        .order-content-main-info-col {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }
      }

      .order-content-row {
        display: flex;

        & + .order-content-row {
          margin-top: 4px;
        }
      }

      .order-col {
        flex-basis: 0;
        flex-grow: 1;
        padding-right: 8px;
      }

      .order-col-file {
        flex-basis: auto;
        flex-grow: 0;
        margin-left: auto;
      }

      .order-col-action {
        padding-left: 0;
        flex-grow: 0;
        flex-basis: auto;
        align-items: center;
        justify-content: center;
        display: flex;

        .nav-icon-button {
          margin-right: -12px;
        }
      }

      .order-action {
        border-top: 1px solid $grey-light;
        margin-top: 8px;
        padding-top: 10px;
      }

    }
  }
}


.order-product-images {
  .order-product-img {
    width: 90px;
    height: 70px;
  }

  &.order-product-images--small {
    .order-product-img {
      width: 56px;
      height: 44px;
    }
  }
}


.order-detail-header {
  display: flex;
  flex-direction: column;

  .order-detail-header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .order-detail-header_col {
    display: flex;
    align-items: center;
  }

  .order-detail-header_col--main {
    .order-date {
      margin-left: 16px;
    }
  }

  .order-detail-header_col--secondary {
    .order-file {
      margin-left: 24px;
    }

    .order-tracking-info {
      margin-left: 32px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.mobile-layout {

    .order-detail-header_col--main {
      max-width: 60%;
    }


    .order-detail-header_col {
      flex-direction: column;
      align-items: flex-start;
      padding-right: 8px;

      .order-date {
        margin-left: 0;
        margin-top: 4px;
      }

      &.order-detail-header_col--secondary {
        gap: 4px;

        .order-tracking-info {
          margin-left: 0;
        }

        .order-file {
          margin-left: -10px;
        }
      }
    }
  }
}
