@use '../abstract' as *;
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";
@use "sass:color";


.comparator-table-helper-right-space {
  margin-right: calc(var(--container-horizontal-spacing) * -1);
  display: flex;
  min-width: 0;
  &:after {
    content: '';
    display: flex;
    flex-shrink: 0;
    width: var(--container-horizontal-spacing);
  }
}
.comparator-table-helper-right-space-in {
  flex-grow: 1;
  min-width: auto;
  display: flex;
}

.comparator-table-paper {
  width: fit-content;
  min-width: 100%;
}

.comparator-table-scroll-helper, .comparator-table-scroll-helper-wrap{
  display: flex;
  flex-direction: column;
}


.comparator-table {
  display: flex;
  flex-direction: column;

  .comparator-table_head {
    display: flex;
    flex-direction: column;
  }

   .comparator-table_head--close .comparator-table_cell {
    padding-top: 0;
    padding-bottom: 0;
    .remove-button {
      margin-bottom: -8px;
      margin-top: -8px;
      position: relative;
      z-index: 20;
    }
  }

  .comparator-table_head--sticky {
    position: sticky;
    top: 0;
    z-index: 15;
    background: $grey-lighter;
  }

  .comparator-table_cells-wrap {
    display: flex;
    flex-grow: 1;
  }

  .comparator-table_cell {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 260px;
    border-right: 1px solid $grey;
    padding: 16px 24px;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    &:last-child {
      border-right: 0;
    }

    &.comparator-table_cell-label {
      min-width: 200px;
      flex-shrink: 0;
      flex-grow: 0;
      padding-left: 0;
      justify-content: flex-start;
      position: sticky;
      left: 0;
      background: $grey-lighter;
      z-index: 11;
      font-weight: 300;
    }
  }
  .comparator-table_row {
    display: flex;
  }



  .comparator-table_body {
    display: flex;
    flex-direction: column;

    .comparator-table_cell {
      border-top: 1px solid $grey;
    }
  }
}




@include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){

  .comparator-table-paper {
    & > .paper {
      padding: 0;
      position: relative;
      overflow: hidden;
    }
  }
  .comparator-table-scroll-helper-wrap {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80px;
      background: linear-gradient(180deg, color.change($grey-lighter, $alpha: 0) 0%, $grey-lighter 70%);
      z-index: 20;
    }
  }
  .comparator-table-scroll-helper {
    overscroll-behavior: contain;
    overflow: auto;
    height: calc(90vh - 80px);
  }
  .comparator-table-helper-right-space-in {
    min-width: 0;
  }


  .comparator-table {
    width: fit-content;
    min-width: 100%;
    .comparator-table_head {

      &.comparator-table_head--sticky{
        z-index: 15;
      }
      .comparator-table_cell-label{
        display: none;
      }
    }

    .comparator-table_cell {
      min-width: 220px;
      max-width: 100%;
      padding: 16px;
      position: sticky;
      left: 0;
      background: $grey-lighter;
      box-shadow: -1px 1px 0px 0px #ccc;
      border: 0;
    }

    .comparator-table_body {
      .comparator-table_row {
        flex-direction: column;
        &:last-child {
          padding-bottom: 80px;
        }
      }
      .comparator-table_cell {
        align-items: flex-start;
        font-weight: 300;
        border-top: 0;
      }


      .comparator-table_cell-label {
        min-width: 100%;
        border-right: 0;
        background: #e5e5e5;
        padding: 0px;
        align-items: flex-start;
        font-size: 12px;
        font-weight: 700;
        box-shadow: none;
        & > span {
          position: sticky;
          left: 0;
          padding: 4px 16px;
        }
      }
    }
  }
}

