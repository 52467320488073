.banner-block {
  display: flex;
  raf-image img {
    border-radius: 8px;
  }
}

.banner-block-main-promo {
   flex-grow: 1;
   max-height: 400px;
    raf-image {
      display: flex;
      border-radius: 8px;
      overflow: hidden;
      img {
        object-fit: cover;
        height: 400px;
        object-position: left bottom;
      }
    }
}

.banner-block-side-promo {
  width: 452px;
  flex-shrink: 0;
  margin-left: 16px;
    & .banner-block-side-promo-item + .banner-block-side-promo-item {
    margin-top: 16px;
    }
}

.banner-block-side-promo-item {
  raf-image {
     border-radius: 8px;
  }
}


@media (max-width: 1300px){
  .banner-block {
    flex-direction: column;

     raf-image img {
      object-fit: contain;
      height: auto;
    }
  }
  .banner-block-side-promo {
    display: none;
  }
}
