@use '../abstract' as *;


.image-container-wrap {
  display: flex;
  flex-shrink: 1;
  min-width: 0;
  min-height: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px;
  overflow: hidden;

  &.with-border {
    border-radius: 10px;
    background: $grey-lighter;
    border: 1px solid $grey-light;
    padding: 10px;
    &.img-loaded {
      background: #fff;
    }

    &.small-size {
      border-radius: 5px;
      padding: 2px;
          }
  }
}
.image-container {
  position: relative;
  display: flex;
  max-height: 100%;
  justify-content: center;

    img {
      object-fit: contain;
    }
}

.image-placeholder {
  background: url("/assets/images/image-placeholder.svg") no-repeat center center;
  position: relative;
  height: 24px;
  width: 30px;
}
