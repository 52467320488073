@use '../../abstract' as *;

.section {

  display: flex;
  flex-direction: column;

  &.fill-space {
    flex-grow: 1;
  }

  &.gutter-top-null-content {
    margin-top: calc(var(--layout-content-spacing-top) * -1);
  }
  &.gutter-bottom-null-content {
    margin-top: calc(var(--layout-content-spacing-bottom) * -1);
  }

  &.gutter-top-negative {
    margin-top: -20px;
  }

  &.gutter-top {
    margin-top: 40px;
  }

  &.gutter-bottom {
    margin-bottom: 40px;
  }



  .section-title {
    margin-bottom: 40px;
  }


  &.section-light {
    position: relative;
    background: $grey-lightest;
    padding: var(--section-vertical-padding) 0;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;


    //&:before, &:after {
    //  content: '';
    //  display: block;
    //  position: absolute;
    //  top: -1px;
    //  bottom: -1px;
    //  background: #f8f8f8;
    //  border-top: 1px solid $grey-light;
    //  border-bottom: 1px solid $grey-light;
    //  width: calc((var(--body-width) - 100%)/2);
    //}
    //&:before {
    //  right: 100%;
    //}
    //&:after {
    //  left: 100%;
    //}
  }
}

