@use '../abstract' as *;

.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
}

.quantity-component {
  display: inline-flex;
  height: 40px;
  align-items: center;

  .quantity-input {
    -moz-appearance: textfield;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 40px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    margin-right: -10px;
    margin-left: -10px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    font-size: 16px;
    &:focus {
      outline: 0;
    }
  }
  .quantity-button {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 0;
    background: $grey;
    position: relative;
    z-index: 0;
    font-size: 16px;
    font-weight: bold;
    color: $grey-dark;

    &.quantity-button-left{
     border-top-left-radius: 10px;
     border-bottom-left-radius: 10px;
      padding-right: 9px;
    }
    &.quantity-button-right{
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-left: 9px;
    }
  }


  &.quantity-component-small {

    .quantity-input {
      width: 48px;
      height: 34px;
    }

    .quantity-button {
      height: 34px;
      width: 34px;
      line-height: 34px;
      font-size: 14px;
    }
  }
}



