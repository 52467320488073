@use '../../abstract' as *;
@use "../../bootstrap/mixins/breakpoints";
@use "../../bootstrap/variables";

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  margin-top: calc(var(--layout-content-spacing-top) * -1);
  p {
    display: inline-block;
  }

  @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
    height: auto;
    min-height: 60px;
    padding-top: 16px;
    padding-bottom: 12px;
  }
}
.breadcrumb-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumb_link-home {
  margin-top: -4px;
}
.breadcrumb_link {
  text-decoration: underline;
  color: $grey-dark;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.breadcrumb_name {
  color: $grey;
}
.breadcrumb_divider {
  color: $grey;
  width: 24px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
}
