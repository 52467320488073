@use "../mixins/calculations" as *;


.sort-menu {
  display: flex;
  align-items: center;
  .sort-menu_label {
    margin-right: 8px;
  }
  .sort-menu_actions {
     .mat-button-base + .mat-button-base {
        margin-left: 4px;
      }
    .mat-button-base {
      font-weight: normal;
      &.active {
        color: #000;
        font-weight: 600;
      }
    }
  }
}
