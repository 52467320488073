@use '../abstract' as *;


.custom-range-slider {

  .mat-range-slider.mat-range-min-slider.mat-slider {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .mat-range-slider.mat-range-max-slider.mat-slider {
    display: block;
    top: 0;
    left: 0;
    right: 0;
  }

  .mat-range-slider.mat-range-min-slider.mat-slider > div > div.mat-slider-ticks-container,
  .mat-range-slider.mat-range-min-slider.mat-slider > div > div.mat-slider-track-wrapper {
    display: none !important;
  }

  .mat-range-slider.mat-range-max-slider.mat-slider > div > div.mat-slider-track-wrapper > .mat-slider-track-fill {
    transform: none !important;
    z-index: 2;

    /* in order for the filltrack not to disappear */
    display: block !important;
  }

  .mat-range-slider.mat-range-max-slider.mat-slider > div > div.mat-slider-track-wrapper > .mat-slider-track-background {
    transform: none !important;
    z-index: 1;
  }

  .mat-range-slider.mat-range-min-slider.mat-slider > div > div.mat-slider-thumb-container {
    z-index: 3;
  }

  .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    border-color: $primary-color;
    background-color: $primary-color;
  }
}
