@use '../abstract' as *;
@use "../mixins/calculations" as *;
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";

.two-side-layout--product-detail {
  margin-bottom: 60px;
  .secondary-side--gallery-side {
    width: 48%;
    flex-shrink: 0;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
  .primary-side {}
}


.product-detail-additional-info {
  max-width: 1250px;

  .two-side-layout {
    .primary-side {
      padding-top: 0;
    }

    .primary-side + .secondary-side {
      width: 380px;
      padding-left: 80px;
      padding-top: 6px;
    }

    @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){
      &.break-lg {

        .secondary-side {
          width: auto;
          padding-right: 0;
          & + .primary-side {
            padding-left: 0px;
          }
        }
        .primary-side {
          & + .secondary-side {
            width: auto;
            padding-left: 0;
          }
        }
      }
    }
  }
}


.product-detail-short-description {
  display: block;
  overflow: hidden;
  max-height: 120px;
  position: relative;
  padding-bottom: 20px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
    background: linear-gradient(180deg, rgba(255,255,255,0), white);
  }
}

