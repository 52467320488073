@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";
@use '../abstract' as *;


.stack {
  display: flex;
  flex-direction: row;

  &.stack-space-between {
    width: 100%;
    justify-content: space-between;
  }
  &.stack-fill-space {
    & > :not(style) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  &.stack-with-dividers {
    & > :not(style) + :not(style) {
      position: relative;

      &:before {
        content: '';
        width: 1px;
        position: absolute;
        height: 100%;
        background: rgba(0,0,0, 0.1);
      }
    }
  }

  &.stack-horizontal {
    flex-direction: row;
    &.stack-center {
      align-items: center;
    }
    &.stack-baseline {
      align-items: baseline;
    }
  }

  & > :not(style) + :not(style) {
      margin-left: 8px;
      &:before {
         left: -4px;
      }
  }

  &.stack-4 > :not(style) + :not(style) {
    margin-left: 4px;
    &:before {
      left: -2px;
    }
  }
  &.stack-8 > :not(style) + :not(style) {
    margin-left: 8px;
    &:before {
      left: -4px;
    }
  }

  &.stack-12 > :not(style) + :not(style) {
    margin-left: 12px;
    &:before {
      left: -6px;
    }
  }

  &.stack-16 > :not(style) + :not(style) {
    margin-left: 16px;
    &:before {
      left: -8px;
    }
  }

  &.stack-24 > :not(style) + :not(style) {
    margin-left: 24px;
    &:before {
      left: -12px;
    }
  }

  &.stack-32 > :not(style) + :not(style) {
    margin-left: 32px;
    &:before {
      left: -16px;
    }
  }

  &.stack-vertical {
    flex-direction: column;
    & > :not(style) + :not(style) {
      margin-left: 0;
      margin-top: 8px;
    }
    &.stack-4 > :not(style) + :not(style) {
      margin-left: 0;
      margin-top: 4px;
    }
    &.stack-8 > :not(style) + :not(style) {
      margin-left: 0;
      margin-top: 8px;
    }

    &.stack-12 > :not(style) + :not(style) {
      margin-left: 0;
      margin-top: 12px;
    }

    &.stack-16 > :not(style) + :not(style) {
      margin-left: 0;
      margin-top: 16px;
    }

    &.stack-24 > :not(style) + :not(style) {
      margin-left: 0;
      margin-top: 24px;
    }

    &.stack-32 > :not(style) + :not(style) {
      margin-left: 0;
      margin-top: 32px;
    }
  }
}


