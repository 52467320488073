


.form-layout {
   display: flex;
  flex-direction: column;
  &.form-layout-medium {
    max-width: 584px;
  }
}

