@use '../abstract' as *;
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";

.sat-popover-container {
  background: #fff;
  border-radius: 8px;
  border: 1px solid $grey;
  padding: 24px;
  box-shadow:
    0px 3.1px 3.6px rgba(0, 0, 0, 0.032),
    0px 8.5px 10px rgba(0, 0, 0, 0.041),
    0px 21px 24.1px rgba(0, 0, 0, 0.049),
    0px 97px 80px rgba(0, 0, 0, 0.07);

  .close-button {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-top: 1px solid #aaa;
    border-right: 1px solid #aaa;
  }

  &.sat-popover-below {
    margin-top: 10px;
    &:after {
      top: 0;
      transform: rotate(-45deg);
      margin-top: -6px;
    }

    &.sat-popover-center {
      &:after {
        right: 50%;
        left: 50%;
        margin-left: -4px;
        transform: rotate(-45deg);
      }
    }

    &.sat-popover-before {
      &:after {
        right: 16px;
        left: auto;
        transform: rotate(-45deg);
      }
    }
    &.sat-popover-after {
      &:after {
        left: 16px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  &.sat-popover-above {
    margin-bottom: 10px;
    &:after {
      bottom: 0;
      transform: rotate(135deg);
      margin-bottom: -6px;
    }

    &.sat-popover-center {
      &:after {
        right: 50%;
        left: 50%;
        margin-left: -4px;
        transform: rotate(135deg);
      }
    }

    &.sat-popover-before {
      &:after {
        right: 16px;
        left: auto;
        transform: rotate(135deg);
      }
    }
    &.sat-popover-after {
      &:after {
        left: 16px;
        right: auto;
        transform: rotate(135deg);
      }
    }
  }
  &.sat-popover-before {
    &:after {
      right: -6px;
      transform: rotate(45deg);
    }
    &.sat-popover-center {
      &:after {
        top: 50%;
        margin-top: -4px;
      }
    }
  }
  &.sat-popover-after {
    &:after {
      left: -6px;
      transform: rotate(-135deg);
    }
    &.sat-popover-center {
      &:after {
        top: 50%;
        margin-top: -4px;
      }
    }
  }

  @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
    &:after {
      display: none;
    }
  }
}
.cdk-overlay-pane {
  &.small-popover {
    max-width: 300px;
    width: 100%;
    .sat-popover-container {
      width: 100%;
    }
  }
}

