@use '../abstract' as *;
@use 'sass:color';


.mobile-menu-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 250;
}


.menu-container-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: color.change($grey-lightest, $alpha: 0.5 );
}
.mobile-menu-container {
  z-index: 10;
  position: relative;
  box-shadow: $shadow-large;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  border: 1px solid $secondary-color-100;
  border-top: 0;

  .mobile-menu-header {
    background: $primary-color;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    min-height: 44px;
    align-items: center;
    padding: 6px 16px;
    color: #fff;


    .mobile-menu-header-title {
      flex-grow: 1;
    }
    .mobile-menu-close-button {
      margin: -4px -12px -4px 0px;
    }

    .mobile-menu-nav-button {
      margin: -4px 0px -4px -12px;
    }
  }


  .mobile-menu-list {
    margin: 0;
    padding: 0;

    &.mat-list-dividers {
      .mat-list-item {
        &:after {
          background: $secondary-color-100;
        }
      }

    }
    &.mobile-menu-list--secondary {
      .mat-list-item {
        background:  $secondary-color-50;
      }
    }
    &.mobile-menu-list--tertiary {
      background:  $grey-lighter;
    }
    .mat-list-item {
      height: auto;
      min-height: 44px;
      display: flex;
      flex-direction: column;

      .mat-list-item-content {
        padding: 0px 16px;
        flex-grow: 1;
        .mat-line {
          min-height: 44px;
          padding: 8px 0;
          display: flex;
          align-items: center;
        }
      }
      .mat-icon-button.mat-button-small {
        margin: -4px -12px -4px 0px;
      }
    }
  }
}

