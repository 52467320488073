.anchor-tab-offset {
  position: absolute;
  top: var(--tab-anchor-offset);
  width: 1px;
  height: 1px;
}

.anchor-tab-offset-wrap {
  position: relative;
}



