@use '../abstract' as *;
@use "../mixins/calculations" as *;


.password-strength-indicator {
  position: absolute;
  top: -12px;
  right: 0;
  font-size: toRem(12);
  color: $grey-dark;

  .password-strength-indicator-element {
    display: inline-flex;
    align-items: center;
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 16px;
      margin-right: 8px;
    }
  }

  .empty {
    &:before {
      background: $warn-color;
    }
  }
  .weak {
    &:before {
      background: #ffa500;
    }
  }
  .ok {
    &:before {
      background: $success-color;
    }
  }

  .strong{
    &:before {
      background: $success-color;
    }
  }
}
