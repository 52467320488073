@use '../abstract' as *;

body {
  .mat-checkbox-inner-container {
    height: 20px;
    width: 20px;
    top: -1px;
  }
  .mat-checkbox-frame {
    border-radius: 4px;
    border-width: 1px;
    background-color: #fff;
    border-color: rgba(0,0,0,0.4);
  }
  .mat-checkbox-background {
    border-radius: 4px;
  }

  .mat-checkbox-label, .mat-radio-label {
    white-space: normal;
  }

}
.formSubmitted {

  .mat-radio-group{
    &.ng-invalid {
      .radioPanel {
        border: 2px solid $warn-color;
      }
    }
  }

  .mat-checkbox {
    &.ng-invalid {
      .mat-checkbox-frame {
        border-color: $warn-color;
      }
      .mat-checkbox-label {
        color: $warn-color;
      }
    }
  }
}
