
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Light.woff') format('woff'),
  url('../assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Bold.woff') format('woff'),
  url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Black.woff') format('woff'),
  url('../assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-BlackItalic.woff') format('woff'),
  url('../assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-MediumItalic.woff') format('woff'),
  url('../assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-BoldItalic.woff') format('woff'),
  url('../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff') format('woff'),
  url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Medium.woff') format('woff'),
  url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Italic.woff') format('woff'),
  url('../assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-LightItalic.woff') format('woff'),
  url('../assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}




@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/HelveticaNeueBold.woff') format('woff'),
  url('../assets/fonts/HelveticaNeueBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/HelveticaNeueMedium.woff') format('woff'),
  url('../assets/fonts/HelveticaNeueMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

