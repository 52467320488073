@use '../abstract' as *;
@use '../mixins/calculations' as *;

body {

  .mat-dialog {

    .mat-dialog-close-button {
      z-index: 20;
    }

    .two-side-layout {
      display: flex;
      padding: 0;
      flex-grow: 1;
      margin-top: -32px;
      margin-bottom: -32px;

      .primary-side {
        background: #fff;
        flex-grow: 1;
        padding: 48px 32px 32px 32px;
        position: relative;
        .back-button {
           position: absolute;
          left: 32px;
          top: 12px;
          font-size: toRem(14);
          color: $grey-dark;
        }
      }

      .secondary-side {
        background: $grey-lighter;
        padding: 48px 32px 32px 32px;
        width: 320px;
      }

      .primary-side + .secondary-side {
        border-left: 1px solid $grey;
      }
    }
  }
}

