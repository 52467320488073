@use '../abstract' as *;

body {

  .mat-list {
    &.mat-list-base {
      padding-top: 0;
    }
  }
  .mat-list-menu {
    padding: 0;
    &.mat-list-base .mat-list-item , &.mat-list-base .mat-list-option  {
      height: auto;
      &.active {
        color: $primary-color;
      }

      &:focus {
        background: none;
      }

      &:after {
        content: '';
        display: block;
        left: 12px;
        right: 12px;
        height: 1px;
        background: $grey-light;
        position: absolute;
        top: 0;

      }

      &:first-child {
        &:after {
          display: none;
        }
      }

      .mat-list-item-content {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    &.mat-list-menu--horizontal {
      display: flex;
      padding: 0 var(--container-horizontal-spacing) 14px var(--container-horizontal-spacing);
      overflow-x: auto;
      scroll-behavior: smooth;


      &:after, &:before {
        content: '';
        display: block;
        right: 0;
        top: 0;
        bottom: 0;
        width: var(--container-horizontal-spacing);
        position: absolute;
        right: 0;
        background: linear-gradient(90deg, transparent, #fff);
        z-index: 2;
      }

      &:before {
        right: auto;
        left: 0;
        background: linear-gradient(-90deg, transparent, #fff);
      }

      &.mat-list-base .mat-list-item   {

        border: 1px solid $grey-light;
        border-radius: 10px;
        padding: 0;
        min-height: 48px;
        display: flex;
        overflow: hidden;
        min-width: 0;
        width: auto;
        flex-shrink: 0;


        .text-component  {
          font-weight: normal;
          white-space: nowrap;
        }
        .mat-list-item-content {
          padding: 8px 16px;
        }

        & + .mat-list-item {
          margin-left: 8px;
        }

        &:after {
          display: none;
        }


      }
    }
  }

  .mat-list--no-padding {
    &.mat-list-base .mat-list-item , &.mat-list-base .mat-list-option {

      .mat-list-item-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .mat-list--small {
    &.mat-list-base .mat-list-item , &.mat-list-base .mat-list-option {
      height: auto;
      min-height: 24px;
      padding: 4px 0;
    }
  }

  .mat-list-dividers {
    &.mat-list-base .mat-list-item , &.mat-list-base .mat-list-option {
      &:after {
        content: '';
        display: block;
        height: 1px;
        background: $grey-light;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      &:first-child {
        &:after {
          display: none;
        }
      }
    }

    &.mat-list-dividers---with-first {
      &.mat-list-base .mat-list-item , &.mat-list-base .mat-list-option {
        &:first-child {
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .mat-nav-list {
    .mat-list-item {
      &.mat-list-item-disabled {
        background-color: transparent;
      }
    }
  }


}
