@use '../abstract' as *;
@use 'sass:color';
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";

.category-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include breakpoints.media-breakpoint-down(xl, variables.$grid-breakpoints) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoints.media-breakpoint-down(sm, variables.$grid-breakpoints) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.category-list-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.category-list-item {
  border-radius: 10px;
  display: flex;
  padding: 8px;
  align-items: center;
  border: 1px solid $grey;
  cursor: pointer;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;

  .mat-ripple-element {
    background: color.change($secondary-color-50, $alpha: 0.5);
  }

  @media (hover: hover) {
    &:hover {
      .category-list-text {
        .text-component {
          text-decoration: underline;
        }
      }
    }
  }

  .category-list-image {
    height: 54px;
    width: 54px;
    margin-right: 16px;
    background: $secondary-color-50;
    border-radius: 8px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;

    .image-container-wrap {
      width: 85%;
    }
  }

  .category-list-text {
    position: relative;
    z-index: 1;
    text-align: left;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .category-count {
      flex-grow: 0;
      flex-shrink: 0;
      width: 4rem;
      text-align: center;
    }
  }
}
