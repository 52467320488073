@use '../abstract' as *;


.price-summary {
  margin:  0;
  position: relative;

  &.with-dividers {
    .price-summary-row {

      &:first-child {
        border-top: 1px solid $grey-light;
        padding-top: 24px;
        margin-top: 16px
      }

      &:last-child {
        border-top: 1px solid $grey-light;
        padding-top: 16px;
        margin-top: 16px;
      }
    }
  }

  .price-summary-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
  }

  .price-summary-main {
     flex-grow: 1;
     padding-right: 24px;
     display: flex;
     align-items: center;
     justify-content: space-between;
    .price-summary-row-label + .price-summary-row-label {
      margin-left: 16px;
    }
  }

  .price-summary-total {
    flex-grow: 1;
  }

  &.mobile-layout {
    .price-summary-main {
      flex-direction: column;
      align-items: flex-start;
      .price-summary-row-label + .price-summary-row-label {
        margin-left: 0;
      }
    }
    .price-summary-total {
      flex-grow: 0;
    }

  }
}

