@use "../../mixins/calculations" as *;
@use '../../abstract' as *;
@use "../../bootstrap/mixins/breakpoints";
@use "../../bootstrap/variables";

@mixin paper-null {
  padding: 0;
  border: 0;
  background: none;
}
.paper {
   padding: var(--paper-spacing);
   display: flex;
   flex-direction: column;
   transition:  200ms background-color ease-in-out, 200ms border ease-in-out ;

  &.paper-error.paper-error {
    border: 1px solid $warn-color;
  }

   &.fill-space {
     flex-grow: 1;
   }

    &.paper-background-primary {
      background: #fff;
    }

    &.paper-background-transparent {
      background: transparent;
    }

    &.paper-primary {
      background: #fff;
    }

    &.paper-secondary {
      background: $grey-lighter;
      border: 1px solid $grey-light;
    }

    &.paper-stroked {
      border: 1px solid $grey-light;
    }

    &.paper-success {
      background: rgb(212 243 192 / 48%);
    }

    &.paper-border-radius-small {
      border-radius: 8px;
    }

    &.paper-border-radius-medium {
      border-radius: 12px;
    }
    &.paper-transparent {
      background: transparent;
      border: 1px solid transparent;
    }

    &.paper-spacing-none {
      padding: 0;
    }
    &.paper-null-all {
      @include paper-null;
    }

    @include breakpoints.media-breakpoint-down(sm, variables.$grid-breakpoints){
      &.paper-null-sm {
        @include paper-null;
      }
    }
    @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints){
      &.paper-null-md {
        @include paper-null;
      }
    }
    @include breakpoints.media-breakpoint-down(lg, variables.$grid-breakpoints){
      &.paper-null-lg {
        @include paper-null;
      }
    }

}

.paper-header {
  padding-top: 4px;
}

.paper-horizontal-padding {
  padding-left: var(--paper-spacing);
  padding-right: var(--paper-spacing);
}

.paper-null-padding {
  margin: calc(var(--paper-spacing) * -1);
}

.paper-null-padding-no-bottom {
  margin: calc(var(--paper-spacing) * -1);
  margin-bottom: 0;
}

.paper-spacing {
  &.mat-list-base .mat-list-item , &.mat-list-base .mat-list-option  {
    .mat-list-item-content {
      padding-left: var(--paper-spacing);
      padding-right: var(--paper-spacing);
    }
    &:first-child {
      .mat-list-item-content {
        padding-top: var(--paper-spacing);
      }
    }
    &:last-child {
      .mat-list-item-content {
        padding-bottom: var(--paper-spacing);
      }
    }
  }
}
