@use '../abstract' as *;
@use "../bootstrap/mixins/breakpoints";
@use "../bootstrap/variables";



body {


  .mat-autocomplete-panel {


    border-radius: 10px;

    &:not([class*=mat-elevation-z]){
      box-shadow: $shadow-large;
    }

    .mat-option {
      font-size: 14px;
      line-height: 21px;
      min-height: 40px;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      &:first-child {
        padding-top: 16px;
      }
      &:last-child {
        padding-bottom: 16px;
      }
    }

  }
}

